<script lang="ts" setup>
import { useStorage, useTimeoutFn } from '@vueuse/core'
import { tokenLayers } from '#core/config/tokens'

const auth = useAuth()
const client = useClient()

// need to keep the step in sync to the browser
const tourStep = useStorage<number | null>('forgd:homepage:tour', null, undefined, {
  // need to force number literals
  serializer: {
    read: (v: any) => v === null ? null : Number.parseFloat(v),
    write: (v: any) => Number(v || 0).toString(),
  },
})

const TOTAL_TOUR_STEPS = 3

// we need to disable the tour permanently for the user once they close it or finish
const stopIsGuidedTourWatcher = watch(tourStep, (val) => {
  if (val > TOTAL_TOUR_STEPS) {
    client.users.updateUser({
      body: {
        isGuidedTourEnabled: false,
      },
    })
    stopIsGuidedTourWatcher()
  }
})

onMounted(() => {
  useTimeoutFn(() => {
    // avoid a flash of the tooltip from server response
    if (tourStep.value === null && auth.me.isGuidedTourEnabled) {
      tourStep.value = 0
    }
  }, 2000)
})

const { project } = storeToRefs(auth)

const tabs = [
  { label: 'Playbook', to: '/playbook' },
  // DEV-1310
  // { label: 'Tools & services', to: '/tools' },
  { label: 'Token Settings', to: '/token-settings' },
]

const route = useRoute()
const tab = computed(() => {
  return tabs.findIndex(t => t.to === route.path)
})

const tgeDate = computed(() => {
  if (!project.value?.tgeDate) {
    return ''
  }
  const date = new Date(project.value.tgeDate)
  return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`
})

const daysUntilTge = computed(() => {
  if (!project.value?.tgeDate) {
    return 0
  }
  const date = new Date(project.value?.tgeDate)
  const now = new Date()
  return Math.floor((date.getTime() - now.getTime()) / (1000 * 60 * 60 * 24))
})

const { data: coingeckoData } = useAsyncData(() => $fetch('/reference/projects', {
  query: {
    ids: project.value?.coingeckoId || project.value?.ticker,
  },
}))

const price = computed(() => {
  if (!coingeckoData.value) {
    return null
  }
  // find based on ticker and coingeckoId
  return coingeckoData.value?.find((p) => {
    return p.id === project.value?.coingeckoId || p.symbol.toLowerCase() === project.value?.ticker?.toLowerCase()
  })?.price || 'n/a'
})

const tokenBaseLayers = computed(() => {
  return (project.value?.baseLayer || []).map((layer) => {
    if (!(layer in tokenLayers)) {
      return false
    }
    return {
      title: layer,
      alt: layer,
      ...tokenLayers[layer],
    }
  }).filter(Boolean)
})
</script>

<template>
  <div data-layout="core/home">
    <NuxtLoadingIndicator />
    <NuxtLayout name="dashboard">
      <template #header>
        <div class="flex gap-4 items-center">
          <img src="/app/illustrations/dashboard-alt.svg" alt="Dashboard Icon" width="32" height="32">
          <div class="text-xl font-semibold flex-shrink-0">
            Welcome to Forgd
          </div>
        </div>
      </template>
      <div>
        <UiAlert
          v-if="project?.isResearch"
          title="You are visiting Forgd as a researcher. You can explore activities and tools using our demo token FRGD."
          class="mb-8"
          inline
        />

        <div class="xl:flex items-start justify-between mb-12 xl:space-y-0 space-y-5">
          <div class="max-w-2xl">
            <div class="text-xl text-forgd-primary-900 font-semibold mb-5">
              <template v-if="project?.tokenListed">
                Optimize Post-TGE Growth for {{ project?.ticker?.toUpperCase() || project?.name }}
              </template>
              <template v-else>
                Prepare for {{ project?.ticker?.toUpperCase() || project?.name }}’s Launch from your pre-TGE Dashboard
              </template>
            </div>
            <div class="text-forgd-gray-600 text-sm mb-5">
              <template v-if="project?.tokenListed">
                Monitor {{ project?.ticker?.toUpperCase() || project?.name }}’s performance and refine your growth strategy with our suite of tools. Leverage actionable insights to sustain your market presence & catalyze protocol growth.
              </template>
              <template v-else>
                Streamline your path to a successful TGE with essential tools and data. Use this dashboard to plan, execute, and manage pre-launch activities seamlessly.
              </template>
            </div>
            <div class="rounded-full h-10 px-3 inline-flex gap-2 items-center text-forgd-gray-600 border border-forgd-gray-600/30 bg-forgd-gray-600/10">
              <div class="font-mono text-[12px] font-[500]">
                Token Base layer
              </div>
              <UAvatarGroup size="xs" :max="5" :ui="{ ring: 'ring-forgd-neutral-600 ring-1' }">
                <UAvatar
                  v-for="(token, key) in tokenBaseLayers"
                  :key="key"
                  v-bind="token"
                  class="bg-black"
                />
              </UAvatarGroup>
            </div>
          </div>
          <div class="grid gap-[10px] max-w-lg grid-cols-2">
            <div class="bg-white rounded-xl border border-forgd-gray-300 p-4 text-center">
              <UiTooltipTour v-model="tourStep" :step="2" :total-steps="TOTAL_TOUR_STEPS" class="relative inline" placement="left" :offset="85">
                <div class="w-[371px] ">
                  <div class="text-base font-semibold mb-3">
                    Explore the status of your project
                  </div>
                  <div class="text-sm text-forgd-gray-400 mb-3">
                    Toggle the status of your token between pre-TGE and listed to explore recommended activities, and available tools. Manage it from the Token Settings page.
                  </div>
                </div>
              </UiTooltipTour>
              <div class="text-xs text-forgd-gray-600 mb-1">
                Token Status
              </div>
              <UiBadge size="sm" color="green">
                {{ project?.tokenListed ? 'Listed' : 'Pre-TGE' }}
              </UiBadge>
            </div>
            <div class="bg-white rounded-xl border border-forgd-gray-300 p-4 text-center">
              <div class="text-xs text-forgd-gray-600 mb-1">
                TGE date
              </div>
              <div class="font-semibold text-[20px]">
                {{ tgeDate }}
              </div>
            </div>
            <div v-if="project?.tokenListed" class="bg-white rounded-xl border border-forgd-gray-300 p-4 text-center">
              <div class="text-xs text-forgd-gray-600 mb-1">
                Price (USD)
              </div>
              <div class="font-semibold text-[20px]">
                <UiOptional v-slot="{ value }" :value="price">
                  <template v-if="value !== 'n/a'">
                    {{ formatFigure(price, 'currency') }}
                  </template>
                  <template v-else>
                    <UiLabel text="N/A" tooltip="To display price a link to a CoinGecko coin page is needed. If you already have your token listed in CoinGecko update it in the token settings page." />
                  </template>
                </UiOptional>
              </div>
            </div>
            <div v-else class="bg-white rounded-xl border border-forgd-gray-300 p-4 text-center">
              <div class="text-xs text-forgd-gray-600 mb-1">
                Days Until TGE
              </div>
              <div class="font-semibold text-[20px]">
                {{ daysUntilTge }}
              </div>
            </div>
            <div class="bg-white rounded-xl border border-forgd-gray-300 p-4 text-center">
              <UiTooltipTour v-model="tourStep" :step="3" :total-steps="TOTAL_TOUR_STEPS" class="relative inline" placement="left" :offset="85">
                <div class="w-[371px] ">
                  <div class="text-base font-semibold mb-3">
                    Token teammates
                  </div>
                  <div class="text-sm text-forgd-gray-400 mb-3">
                    Admins of projects have the ability to send invitation to new potential teammates. Manage them from the Token Settings page.
                  </div>
                </div>
              </UiTooltipTour>
              <div class="text-xs text-forgd-gray-600 mb-1">
                Active Teammates
              </div>
              <div class="font-semibold text-[20px]">
                {{ auth.organization?.membersCount }}
              </div>
            </div>
          </div>
        </div>
        <div class="relative">
          <UiTooltipTour v-model="tourStep" :step="1" :total-steps="TOTAL_TOUR_STEPS" class="relative inline" placement="right" :offset="120">
            <div class="w-[371px] ">
              <div class="text-base font-semibold mb-3">
                Explore recommended activities and tools
              </div>
              <div class="text-sm text-forgd-gray-400 mb-3">
                Learn about our recommended playbook based on the status of your token, and explore tools that will help you achieve your goals.
              </div>
            </div>
          </UiTooltipTour>
        </div>
        <UiTabs :model-value="tab" :items="tabs" />
        <div>
          <slot />
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>
